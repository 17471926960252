<template>
  <v-container fluid class="ml-10">
    <br /><br />
    <v-row>
      <v-col cols="12" v-if="!payment_title">
        <h1 class="mr-8">
          <v-btn
            fab
            dark
            small
            color="#479b25"
            @click="$router.push(`/membresias`)"
          >
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Detalle de membresía
        </h1>
      </v-col>

      <v-col cols="12" v-if="payment_title">
        <h1 class="mr-8">
          <v-btn fab dark small color="#479b25" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          {{ getFormatTitle(payment_title) }}
        </h1>
      </v-col>
      <v-col>
        <br />
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7">
              <h2>Socio titular</h2>
              <br />
              <v-card max-width="600" class="mt-4">
                <v-row>
                  <v-col
                    cols="3"
                    class="ml-5"
                    v-if="
                      membership &&
                      membership.partner &&
                      membership.partner.photo_url
                    "
                  >
                    <v-img
                      style="border-radius: 10px"
                      :src="membership.partner.photo_url"
                      width="100px"
                      height="100"
                    />
                  </v-col>
                  <v-col cols="3" class="ml-5" v-else>
                    <v-img
                      style="border-radius: 10px"
                      src="@/assets/images/person.jpg"
                      alt=""
                      width="100px"
                      height="100"
                    />
                  </v-col>
                  <v-col cols="7" v-if="membership && membership.partner">
                    <v-card-title>
                      <h4 style="color: #479b25">
                        {{ membership.partner.fullname }}
                      </h4>
                    </v-card-title>
                    <v-card-subtitle>
                      <!-- Membresia básica <br /> -->
                      <strong>{{ membership.partner.document.name }}:</strong>
                      {{ membership.partner.document_number }}
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="5">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYXxlbnwwfHwwfHw%3D&w=1000&q=80"
import { mapState, mapActions } from "vuex";
export default {
  name: "MebershipDetail",
  data: () => ({
    // membership: null
  }),
  computed: {
    ...mapState({
      membership: (state) => state.membership.membership,
      fetchingData: (state) => state.fetchingData,
      payment_title: (state) => state.payment.payment_title,
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions(["getMembershipById"]),
    getFetch() {
      if (this.id) this.getMembershipById({ id: this.id });
    },
    getFormatTitle(f) {
      if (f) {
        const meses = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        let date = new Date(f);
        return meses[date.getMonth()] + " " + date.getFullYear();
      }
      return "";
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style>
</style>